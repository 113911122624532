<template>
	<div class="district-rank">
		<w-navTab titleText="爱心排行"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="nav-top">
					<w-tab :active="active" :data="loveData" @click="onClick"></w-tab>
				</div>
				<div class="head">
					<div class="head-nav">
						<div :class="[{ color: module == 1 }]" @click="onChange(1)">当日排名</div>
						<div :class="[{ color: module == 2 }]" @click="onChange(2)">当月排名</div>
						<div :class="[{ color: module == 3 }]" @click="onChange(3)">年度排名</div>
						<div :class="[{ color: module == 4 }]" @click="onChange(4)">去年排名</div>
					</div>
					<div class="head-top">
						<div class="head-item m-top" v-for="(item, index) in rankList" v-if="index == 1">
							<div class="head-sculpture sculpture"><img :src="item.avatar" alt="" /></div>
							<div class="head-item-picture picture">
								<img src="@/assets/img/icon208.png" alt="" />
							</div>
							<div class="head-item-title">{{ item.title }}</div>
							<div class="head-item-text">{{ item.total ? item.total : 0 }}</div>
						</div>
						<div class="head-item" v-for="(vo, index) in rankList" v-if="index == 0">
							<div class="head-sculpture"><img :src="vo.avatar" alt="" /></div>
							<div class="head-item-picture">
								<img src="@/assets/img/icon207.png" alt="" />
							</div>
							<div class="head-item-title">{{ vo.title }}</div>
							<div class="head-item-text">{{ vo.total ? vo.total : 0 }}</div>
						</div>
						<div class="head-item m-top" v-for="(to, index) in rankList" v-if="index == 2">
							<div class="head-sculpture sculpture"><img :src="to.avatar" alt="" /></div>
							<div class="head-item-picture picture">
								<img src="@/assets/img/icon209.png" alt="" />
							</div>
							<div class="head-item-title">{{ to.title }}</div>
							<div class="head-item-text">{{ to.total ? to.total : 0 }}</div>
						</div>
					</div>
					<div class="head-bottom">
						<div class="head-bottom-left">
							<div class="title">排序</div>
							<div class="text">姓名</div>
						</div>
						<div class="text">爱心值</div>
					</div>
					<div class="middle" v-for="(tp,index) in rankList" v-if="index > 2">
						<div class="middle-item">
							<div class="item-top">
								<div class="item-top-left">
									<div class="item-top-left-number">{{ index + 1 }}</div>
									<div class="item-top-left-picture">
										<img :src="tp.avatar" alt="" />
									</div>
									<div class="item-top-left-box">
										<div class="title">{{ tp.title }}</div>
										<div class="text">{{ tp.level_text }}</div>
									</div>
								</div>
								<div class="item-top-right">{{ tp.total ? tp.total : 0 }}</div>
							</div>
						</div>
					</div>
					
				</div>
			</VantList>
		</div>
	</div>
</template>

<script>
	import WTab from '@/components/Tab';
	import VantList from '@/components/VantList';
	import messageApi from '@/api/message';
	import LoadingPage from '@/components/LoadingPage';
	export default {
		name: 'DistrictRank',
		data() {
			return {
				loading: true,
				finished: false,
				isEmpty: false,
				loadingPage: true,
				active: 0,
				loveData: [{
						type: 1,
						title: '个人排名'
					},
					{
						type: 2,
						title: '大队排名'
					}
				],
				module: 1,
				rankList: [],
				group: {
					page: 1,
					limit: 20
				},
				type: 1,
			};
		},
		props: {
			groupId: {
				default: false
			}
		},
		created() {
			this.id = this.groupId;
			this.onLoad();
		},
		methods: {
			onLoad() {
				this.loading = true;

				let params = {
					type: this.type,
					order: this.module,
					page: this.group.page,
					limit: this.group.limit
				};

				messageApi.districtLoveRank(params)
					.then(result => {
						this.group.page++;
						this.rankList = this.rankList.concat(result.data.list);
						this.loading = false;
						this.loadingPage = false;
						if (this.rankList.length <= 0) {
							this.isEmpty = true;
						}

						if (this.rankList.length >= result.data.total) {
							this.finished = true;
						}
					})
					.catch(error => {});
			},
			onClick(key, vo) {
				this.active = key;
				this.type = vo.type;
				this.group.page = 1,
				this.finished = false;
				this.isEmpty = false;
				this.rankList = []
				this.onLoad();
			},
			onChange(module) {
				this.module = module;
				this.group.page = 1,
				this.finished = false;
				this.isEmpty = false;
				this.rankList = []
				this.onLoad();
			}
		},
		components: {
			WTab,
			VantList,
			LoadingPage
		}
	};
</script>

<style lang="less" scoped>
	.district-rank {
		background: #f2f2f2;

		.container {
			padding-top: 13px;

			.list-view {
				height: 100vh;
			}

			.nav-top {
				padding-left: 10px;
			}

			.head {
				background: #fff;
				border-radius: 15px 15px 0px 0px;
				padding: 20px 10px;
				padding-bottom: 10px;
				margin-top: 12px;

				.head-nav {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 10px;
					border-bottom: 1px solid #ddd;

					div {
						font-size: 16px;
						line-height: 23px;
						color: #000;
						padding-bottom: 12px;
						margin-bottom: -1px;
					}
				}

				.color {
					font-weight: bold;
					color: #FF6969 !important;
					border-bottom: 2px solid #FF6969;
				}

				.head-top {
					display: flex;
					justify-content: space-between;
					padding: 20px 10px 15px;

					.head-item {
						position: relative;
						text-align: center;

						.head-sculpture {
							width: 102px;
							min-width: 102px;
							height: 101px;
							margin: 0 auto;
							border-radius: 50%;
							overflow: hidden;

							img {
								display: block;
								width: 100%;
								height: 100%;
								border-radius: 50%;
								object-fit: cover;
							}
						}

						.sculpture {
							width: 72px;
							min-width: 72px;
							height: 72px;
							border-radius: 50%;
						}

						.head-item-picture {
							width: 115px;
							min-width: 115px;
							height: 115px;
							position: absolute;
							top: -9px;
							margin-left: 50%;
							transform: translateX(-50%);

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.picture {
							width: 85px;
							min-width: 85px;
							height: 85px;
						}

						.head-item-title {
							width: 100px;
							min-width: 100px;
							font-weight: bold;
							font-weight: 700;
							font-size: 18px;
							line-height: 26px;
							text-align: center;
							color: #FF6969;
							margin-top: 5px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.head-item-text {
							font-size: 14px;
							line-height: 20px;
							color: #666;
						}
					}

					.m-top {
						margin-top: 50px;
					}
				}

				.head-bottom {
					padding: 5px 10px;
					background: #f2f2f2;
					border-radius: 6px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.head-bottom-left {
						display: flex;
						justify-content: flex-start;
						align-items: center;

						.title {
							font-size: 16px;
							line-height: 26px;
							color: #666;
							margin-right: 44px;
						}
					}

					.text {
						font-size: 16px;
						line-height: 26px;
						color: #666;
					}
				}

				.middle {
					margin-top: 10px;
					padding: 0 10px;
					border-radius: 6px;
					background-color: #fff;
					border-bottom: 1px solid #f2f2f2;

					.middle-item {
						.item-top {
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding-bottom: 10px;

							.item-top-left {
								display: flex;
								justify-content: flex-start;
								align-items: center;

								.item-top-left-number {
									font-weight: bold;
									font-size: 18px;
									line-height: 20px;
									color: #FF6969;
									margin-right: 15px;
								}

								.item-top-left-picture {
									width: 40px;
									min-width: 40px;
									height: 40px;
									margin-right: 10px;

									img {
										width: 100%;
										height: 100%;
										border-radius: 50%;
										object-fit: cover;
									}
								}

								.item-top-left-box {
									.title {
										font-size: 16px;
										line-height: 23px;
										color: #333;
									}

									.text {
										font-size: 14px;
										line-height: 21px;
										color: #666;
									}
								}
							}

							.item-top-right {
								font-size: 24px;
								line-height: 36px;
								color: #FF6969;
							}
						}
					}
				}
			}
		}
	}
</style>
